<template>
  <v-container fluid>
    <!-- Title -->
    <v-row no-gutters>
      <v-col cols="12">
        <v-card color="transparent" flat dark>
          <div class="text-h4 font-weight-bold text-center" style="padding: 5vh">Threshold Setting</div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Body -->
    <v-row no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-card rounded="lg" width="90%" class="pa-2">
            <v-card-subtitle style="display: inline-block">Threshold Setting</v-card-subtitle>
            <div style="display: inline-block; float: right" class="mt-4 mr-4">
              <v-btn small depressed @click="resetQueue">Reset Current Queues</v-btn>
            </div>

            <v-card-text>
              <v-data-table hide-default-footer :headers="queueSetting.headers" :items="queueSetting.items">
                <!-- Average Waiting Time -->
                <template v-slot:[`item.average_waiting_time`]="{ item }">
                  <v-btn elevation="0" fab small disabled class="ml-2" style="color: black !important">
                    {{ item.average_waiting_time }}
                  </v-btn>
                </template>

                <!-- Numpad Threshold -->
                <template v-slot:[`item.numpad_threshold`]="{ item }">
                  <v-text-field type="number" style="max-width: 60px" v-model="item.numpad_threshold" @change="updateNumpadThreshold($event, item.service_type_id)" hide-details dense outlined />
                </template>

                <!-- SMS Threshold -->
                <template v-slot:[`item.sms_threshold`]="{ item }">
                  <v-text-field type="number" style="max-width: 60px" v-model="item.sms_threshold" @change="updateSMSThreshold($event, item.service_type_id)" hide-details dense outlined />
                </template>

                <!-- Threshold -->
                <template v-slot:[`item.threshold`]="{ item }">
                  <div class="d-flex align-center my-2">
                    <v-text-field style="max-width: 50px" disabled v-model="item.threshold" hide-details dense outlined :value="item.threshold" />
                    <v-slider class="px-1" style="width: 250px" @change="changeThreshold(item.service_type_id, item.threshold)" hide-details dense v-model="item.threshold" max="360" />
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'QueueSetting',
  computed: { ...mapState(['queueSetting']) },
  methods: {
    ...mapActions(['resetQueue', 'fetchServiceTypeList', 'updateServiceTypeThreshold']),
    changeThreshold(service_type_id, threshold) {
      this.updateServiceTypeThreshold({ params: { service_type_id, threshold } })
    },
    updateNumpadThreshold(threshold, service_type_id) {
      if (Number(threshold) && threshold >= 0) {
        this.updateServiceTypeThreshold({ params: { service_type_id, numpad_threshold: threshold } })
      } else this.$store.commit('UPDATE_QUEUE_ITEM', { service_type_id, type: 'numpad_threshold', value: 0 })
    },
    updateSMSThreshold(threshold, service_type_id) {
      if (Number(threshold) && threshold >= 0) {
        this.updateServiceTypeThreshold({ params: { service_type_id, sms_threshold: threshold } })
      } else this.$store.commit('UPDATE_QUEUE_ITEM', { service_type_id, type: 'sms_threshold', value: 0 })
    }
  },
  mounted() {
    this.fetchServiceTypeList()
  }
}
</script>
