var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"transparent","flat":"","dark":""}},[_c('div',{staticClass:"text-h4 font-weight-bold text-center",staticStyle:{"padding":"5vh"}},[_vm._v("Threshold Setting")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"pa-2",attrs:{"rounded":"lg","width":"90%"}},[_c('v-card-subtitle',{staticStyle:{"display":"inline-block"}},[_vm._v("Threshold Setting")]),_c('div',{staticClass:"mt-4 mr-4",staticStyle:{"display":"inline-block","float":"right"}},[_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":_vm.resetQueue}},[_vm._v("Reset Current Queues")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.queueSetting.headers,"items":_vm.queueSetting.items},scopedSlots:_vm._u([{key:"item.average_waiting_time",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",staticStyle:{"color":"black !important"},attrs:{"elevation":"0","fab":"","small":"","disabled":""}},[_vm._v(" "+_vm._s(item.average_waiting_time)+" ")])]}},{key:"item.numpad_threshold",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"max-width":"60px"},attrs:{"type":"number","hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.updateNumpadThreshold($event, item.service_type_id)}},model:{value:(item.numpad_threshold),callback:function ($$v) {_vm.$set(item, "numpad_threshold", $$v)},expression:"item.numpad_threshold"}})]}},{key:"item.sms_threshold",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"max-width":"60px"},attrs:{"type":"number","hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.updateSMSThreshold($event, item.service_type_id)}},model:{value:(item.sms_threshold),callback:function ($$v) {_vm.$set(item, "sms_threshold", $$v)},expression:"item.sms_threshold"}})]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center my-2"},[_c('v-text-field',{staticStyle:{"max-width":"50px"},attrs:{"disabled":"","hide-details":"","dense":"","outlined":"","value":item.threshold},model:{value:(item.threshold),callback:function ($$v) {_vm.$set(item, "threshold", $$v)},expression:"item.threshold"}}),_c('v-slider',{staticClass:"px-1",staticStyle:{"width":"250px"},attrs:{"hide-details":"","dense":"","max":"360"},on:{"change":function($event){return _vm.changeThreshold(item.service_type_id, item.threshold)}},model:{value:(item.threshold),callback:function ($$v) {_vm.$set(item, "threshold", $$v)},expression:"item.threshold"}})],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }